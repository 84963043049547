/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
var site = function () {

    this.hWindow = $(window).height();

    this.global = function () {

        $('[data-toggle="tooltip"]').tooltip();

        if (typeof WOW === "function") {
            var wow = new WOW({
                boxClass: 'wow',        // animated element css class (default is wow)
                animateClass: 'animated',   // animation css class (default is animated)
                offset: 0,            // distance to the element when triggering the animation (default is 0)
                mobile: true,         // trigger animations on mobile devices (default is true)
                live: true          // act on asynchronously loaded content (default is true)
            });
            wow.init();
        }
    }

    

    this.configForm = function () {
        $('.mask-phone').mask('(00)000000009');
        $('.mask-date').mask('00/00/0000');
        $('.mask-schedule').mask('00:00');
        $('.mask-cpf').mask('000.000.000-00');
        $('.mask-cnpj').mask('00.000.000/0000-00');
        $('.mask-cep').mask('00000-000');
        $('.mask-money').mask('#.##0,00', { reverse: true });
        $('.custom-file-input').on('change', function () {
            var fileName = document.getElementById("f-attachment").files[0].name;
            $('.custom-file-label').text(fileName);
        })
    }

    this.scroll = function () {
        $('a:not(.lnk-tabpanel)[href^="#"]').on('click', function (e) {
            e.preventDefault();
            var target = this.hash;
            var $target = $(target);

            $('html, body').stop().animate({
                'scrollTop': $target.offset().top - 100
            }, 900, 'swing', function () {
                window.location.hash = target;
            });
        });
    };

    this.validate = function () {
        var objThis = this;
        if (typeof $.fn.validate === "function") {
            var formSimulator = $('#frm-simulator');
            if(formSimulator.length){
                formSimulator.validate({
                    submitHandler: function() {
                        objThis.send(formSimulator, 'simulator');
                    },
                    rules: {
                        txt_value   : "required",
                        txt_name    : "required",
                        txt_email : {
                            required : true,
                            email    : true
                        },
                        txt_phone   : "required",
                        ddl_type    : "required"

                    },
                    messages: {
                        txt_value   : "Informe um valor",
                        txt_name    : "Informe seu nome",
                        txt_email   : {
                            required : "Informe seu e-mail",
                            email    : "Formato de e-mail inválido"
                        },
                        txt_phone   : "Informe seu telefone",
                        ddl_type    : "Selecione o tipo de imóvel"
                    }
                });
            };

            var formContact = $('#frm-contact');
            if (formContact.length) {
                formContact.validate({
                    submitHandler: function () {
                        objThis.send(formContact, 'send');
                    },
                    rules: {
                        txt_name: "required",
                        txt_phone: "required",
                        txt_email: "required",
                        ddl_channel: "required",
                        txta_message: "required"
                    },
                    messages: {
                        txt_name: "Informe seu nome",
                        txt_phone: "Informe seu telefone",
                        txt_email: "Informe seu e-mail",
                        ddl_channel: "Selecione o canal",
                        txta_message: "Deixe uma mensagem"
                    }
                });
            };

            var formNewsletter = $('#frm-newsletter');
            if (formNewsletter.length) {
                formNewsletter.validate({
                    submitHandler: function () {
                        objThis.send(formNewsletter, 'newsletter');
                    },
                    rules: {
                        txt_email : {
                            required : true,
                            email    : true
                        },
                    },
                    messages: {
                        txt_email   : {
                            required : "Informe seu e-mail",
                            email    : "Formato de e-mail inválido"
                        },
                    }
                });
            };
        }
    };

    this.send = function (form, uri) {
        $('.msg-registration').remove();
        $('button.send', form).prop('disabled', true);
        var data = form.serialize();
        var labelButtomDefault = $('button.send', form).html();
        $('button.send', form).html('Aguarde!...');
        $.ajax({
            url: base_url + 'contact/' + uri,
            type: 'POST',
            data: data,
            success: function (data) {
                console.log(data);
                $('.msg-registration').remove();
                try {
                    var r = JSON.parse(data);
                    if('out_range' === r._return){
                        alert(r._msg);
                        $('button.send', form).prop('disabled',false).html(labelButtomDefault);
                    }else{
                        if ('success' === r._return) {
                            form[0].reset();
                            var formId = form.attr('id');
                           
                            if(formId == 'frm-simulator'){
                                gtag('event', 'conversion', {'send_to': 'AW-781396928/-X7CCNHS9aEBEMDXzPQC'}); 
                            }
                            if(formId == 'frm-contact'){
                                gtag('event', 'conversion', {'send_to': 'AW-781396928/Phg5CMif66EBEMDXzPQC'});
                            }
                            if(formId == 'frm-newsletter'){
                                form.append('<div class="msg-registration success">Seu e-mail foi cadastrador com sucesso.</div>');
                                $('button.send', form).prop('disabled',false).html(labelButtomDefault);
                            }
                            $('button.send', form).prop('disabled', false).html(labelButtomDefault);
                            form.append('<div class="msg-registration success">' + r._msg + '</div>');
                        } else {
                            form.append('<div class="msg-registration error">Ops! algo deu errado.</div>');
                            $('button.send', form).prop('disabled', false).html(labelButtomDefault);
                        }
                    }
                } catch (e) {
                    form.append('<div class="msg-registration error">Ops! algo deu errado.</div>');
                    $('button.send', form).prop('disabled', false).html(labelButtomDefault);
                }
            },
            error: function () {
                $('.msg-registration').remove();
                $('button', form).append('<div class="msg-registration error">Ops! algo deu errado.</div>');
                $('button', form).prop('disabled', false).html(labelButtomDefault);
            }
        });
        return false;
    };
};

var objSite = new site();
$(window).on('load', function () {
    objSite.global();
});

$(window).on('resize', function () {
    objSite.hWindow = $(window).height();
});

$(function () {
    objSite.scroll();
    objSite.configForm();
    objSite.validate();
});